import React, { useEffect, useState, useRef } from "react";
import "./Footer.css";
import { Link as Anchor } from "react-router-dom";
import logo from "../../images/logo.png";
import globalLogo from "../../images/globalLogo.png";
import baseURL from "../url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

export default function Footer() {
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [contactos, setContactos] = useState([]);
  useEffect(() => {
    cargarProductos();
  }, []);
  const cargarProductos = () => {
    fetch(`${baseURL}/productosGet.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        const categoriasMap = new Map();
        data.productos.forEach((producto) => {
          const categoria = producto.categoria;
          if (categoriasMap.has(categoria)) {
            categoriasMap.get(categoria).push(producto);
          } else {
            categoriasMap.set(categoria, [producto]);
          }
        });
        const categoriasArray = Array.from(
          categoriasMap,
          ([categoria, productos]) => ({ categoria, productos })
        );
        setCategorias(categoriasArray?.slice(0, 3));
        setProductos(data.productos?.slice(0, 3));
      })
      .catch((error) => console.error("Error al cargar productos:", error));
  };

  useEffect(() => {
    cargarContacto();
  }, []);

  const cargarContacto = () => {
    fetch(`${baseURL}/contactoGet.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setContactos(data.contacto.reverse()[0] || []);
      })
      .catch((error) => console.error("Error al cargar contactos:", error));
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#0C71CF",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#FFF",
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  return (
    <div className="FooterContain">
      <div class="custom-shape-divider-top-1726691174">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container sx={{ mb: 0, alignItems: "center" }}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Item elevation={0}>
              <Anchor to="" target="_blank">
                {" "}
                <img src={logo} alt="logo" sx={{ width: "75%" }} />
              </Anchor>
              <Typography variant="h6" gutterBottom sx={{ color: "#000" }}>
                Distribuidora Jimdia, S.A.
              </Typography>
              <Box className="socials" sx={{ justifyContent: "center" }}>
                <Anchor to={contactos.instagram} target="_blank">
                  <i className="fa fa-instagram"></i>
                </Anchor>
                <Anchor to={`https://wa.me/50689373790`} target="_blank">
                  <i className="fa fa-whatsapp"></i>
                </Anchor>
                <Anchor to={contactos.facebook} target="_blank">
                  <i className="fa fa-facebook"></i>
                </Anchor>
              </Box>
            </Item>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Item elevation={0}>
              <Typography variant="h6" gutterBottom sx={{ color: "#000" }}>
                Contacto
              </Typography>
            </Item>
            <Item elevation={0}>
              <Anchor
                to={`mailto:ventas@distjimdia.com`}
                target="_blank"
                className="text-white"
              >
                Email: ventas@distjimdia.com
              </Anchor>
            </Item>
            <Item elevation={0}>
              <Anchor
                to={`tel:+50622350585`}
                target="_blank"
                className="text-white"
              >
                Tel: +506 2235-0585
              </Anchor>
              <br />
              <Anchor
                to={`tel:+50622412583`}
                target="_blank"
                className="text-white"
              >
                Tel: +506 2241-2583
              </Anchor>
              <br />
              <Anchor
                to={`https://wa.me/50689373790`}
                target="_blank"
                className="text-white"
              >
                Whatsapp: +506 8937-3790
              </Anchor>
            </Item>
            <Item elevation={0} sx={{ mt: 4 }}>
              <Anchor
                to={`https://www.google.com/maps?q=${encodeURIComponent(
                  contactos.direccion
                )}`}
                target="_blank"
                className="text-white"
              >
                Colima de Tibás, San José.
              </Anchor>
            </Item>
            <Item elevation={0}>
              <Anchor
                to="https://maps.app.goo.gl/jPQw5zYDf3AAC4sY7"
                target="_blank"
                className="text-white"
              >
                Ver ubicación en el mapa
              </Anchor>
            </Item>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }} sx={{ textAlign: "center" }}>
            <Item elevation={0}>
              <Typography variant="h6" gutterBottom sx={{ color: "#000" }}>
                ¿Quiénes somos?
              </Typography>
            </Item>
            <Typography variant="subtitle1" gutterBottom sx={{ color: "#FFF" }}>
              Venta y distribución de materias primas para la fabricación de
              productos de limpieza para el hogar y la industria.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 12 }} sx={{ mt: 2, mb: 0 }}>
            <Item
              elevation={0}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p className="display-flex global-text">Designed By</p>
                <img
                  src={globalLogo}
                  alt="Global Solutions Logo"
                  loading="lazy"
                  className="global-logo"
                />
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
